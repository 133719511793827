const changeScroll = (e: WheelEvent, element: Element): void => {
    let modifier;
    if (e.deltaMode === e.DOM_DELTA_PIXEL) {
        modifier = 1;
    } else if (e.deltaMode === e.DOM_DELTA_LINE) {
        modifier = parseInt(getComputedStyle(element).lineHeight, 10);
    } else if (e.deltaMode === e.DOM_DELTA_PAGE) {
        modifier = element.clientHeight;
    }
    if (e.deltaY !== 0) {
        element.scrollLeft += modifier * e.deltaY;
        e.preventDefault();
    }
};

export { changeScroll };
