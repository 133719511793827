
import Vue from 'vue';

import theme from 'Theme';
import { assetsTypes } from 'Store/v2/Assets';
import BalanceModal from 'Modules/AccountPortfolio/components/BalanceModal.vue';

/*
* emits
* toggle-modal: (data: boolean) => void;
* */

interface Props {
    balance: any;
    isDeFi: boolean;
    isWorkspace: boolean;
}

interface Data {
    theme: any;
    assetsTypes: any;
}

interface Computed {
    precision: number;
    free: number;
    summaryHold: number;
}

export default Vue.extend<Data, any, Computed, Props>({
    components: {
        BalanceModal,
    },
    props: {
        balance: {
            type: undefined,
            required: true,
        },
        isDeFi: {
            type: Boolean,
            default: false,
        },
        isWorkspace: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
            assetsTypes,
            showModal: false,
        };
    },
    computed: {
        precision() {
            if (this.balance.assetType === this.assetsTypes.FIAT) {
                return 2;
            }
            if (this.balance.assetType === this.assetsTypes.CRYPTO_SPOT) {
                return 8;
            }
            if (this.balance.assetType === this.assetsTypes.STABLE_COIN) {
                return 4;
            }
            return 8;
        },
        summaryHold() {
            return this.balance.hold + (this.balance.positionsHold ?? 0);
        },
        free() {
            if (this.balance.free < 0) {
                return 0;
            }
            return this.balance.free;
        },
    },
    methods: {
        getMinimumBalance(precision: number) {
            let value = '< 0.';
            for (let i = 1; i < precision; i += 1) {
                value += '0';
            }
            value += '1';
            return value;
        },
        onShow() {
            this.showModal = true;
            this.$emit('toggle-modal', true);
        },
        onHide() {
            this.showModal = false;
            this.$emit('toggle-modal', false);
        },
        hideModal() {
            this.$refs.modalRef.showPopper = false;
        },
    },
});
